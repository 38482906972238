<template>
  <div v-if="isLoaded" style="heigh:100vh;">
    <div id="fb-root"></div>
    <Profile :user="user"></Profile>
    <v-card v-if="tasks.length !== 0"
    class="mx-auto my-7" elevation="6"
    :width="$vuetify.breakpoint.mobile ? '80%' : '50%'">
      <v-card-title>Voluntary Work</v-card-title>
      <v-simple-table>
        <template v-slot>
          <thead>
            <tr>
              <th>Project</th>
              <th>Charity</th>
              <th>Participation</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="task in tasks" :key="task.project.projectId"
                @click="navigate(task)">
              <td>{{task.project.title}}</td>
              <td>{{task.project.charityName}}</td>
              <td>{{task.total}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-card v-if="donations.length !== 0"
        class="mx-auto my-7" elevation="6"
        :width="$vuetify.breakpoint.mobile ? '80%' : '50%'">
      <v-card-title>Donations</v-card-title>
      <v-simple-table>
        <template v-slot>
          <thead>
            <tr>
              <th>Project</th>
              <th>Charity</th>
              <th>Amount ($MYR)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="donation in donations" :key="donation.project.projectId"
                @click="navigate(donation)">
              <td>{{donation.project.title}}</td>
              <td>{{donation.project.charityName}}</td>
              <td>{{donation.total}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
  <div v-else >
    <v-skeleton-loader type="card-avatar, list-item"  class = "mx-auto my-10"
      max-height="60vh" max-width="60vw"></v-skeleton-loader>
  </div>
</template>

<script>
import Profile from '@/components/account/Profile.vue'
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
export default {
  components: {
    Profile
  },
  data () {
    return {
      isLoaded: false,
      tasks: [],
      donations: [],
      user: { name: '' }
    }
  },
  methods: {
    ...mapActions('giverStore', ['getGiverByEmail', 'containsGiver', 'searchExactGiver', 'requestContributionsOf']),
    ...mapActions('charityStore', ['searchCharities']),
    navigate (obj) {
      this.$router.push({
        name: 'ProjectDetailsView',
        params: {
          root: obj.project.charityEmail,
          projectId: obj.project.projectId
        }
      })
    }
  },
  computed: {
    ...mapGetters('charityStore', ['charities'])
  },
  /**
   * Retrieve charity data from either local or backend account service
   */
  created () {
    const segment = Vue.$pathConverter.getLastSegmentOf(window.location.href)
    this.containsGiver(segment).then(yes => {
      if (yes) {
        // console.log(`Giver ${segment} has been cahced. Load it locally`)
        this.isLoaded = true
        this.getGiverByEmail(segment).then(giver => {
          this.user = giver
        })
      } else {
        this.isLoaded = false
        this.searchExactGiver(segment).then((giver) => {
          // console.log(giver)
          if (giver) {
            this.user = giver
            this.user.name = `${giver.firstName} ${giver.lastName}`
            // console.log(`Charity ${this.user.email} is loaded from backend Account Service`)
            this.isLoaded = true
          } else {
            throw new Error()
          }
        })
      }
    })
    this.requestContributionsOf(segment).then((contributions) => {
      // console.log(contributions)
      if (contributions.tasks) {
        this.tasks = contributions.tasks
        this.tasks.forEach((task, i) => {
          this.searchCharities({ content: `id:${task.project.charitySub}` })
            .then(() => {
              const foundcharities = Object.values(this.charities).filter((charity) => {
                return charity.userId === task.project.charitySub
              })
              if (foundcharities) {
              // since it is certain that userId or sub is always unique in the system
                this.$set(this.tasks[i].project, 'charityName', foundcharities[0].name)
                this.$set(this.tasks[i].project, 'charityEmail', foundcharities[0].email)
              }
            })
        })
      }

      if (contributions.donations) {
        this.donations = contributions.donations

        this.donations.forEach((donation, i) => {
          this.searchCharities({ content: `id:${donation.project.charitySub}` })
            .then(() => {
              const foundcharities = Object.values(this.charities).filter((charity) => {
                return charity.userId === donation.project.charitySub
              })
              if (foundcharities) {
              // since it is certain that userId or sub is always unique in the system
                this.$set(this.donations[i].project, 'charityName', foundcharities[0].name)
                this.$set(this.donations[i].project, 'charityEmail', foundcharities[0].email)
              }
            })
        })
      }
    })
  }
}
</script>
